@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base {
  html {
    font-family: DM Sans, system-ui, sans-serif;
  }
} */

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
input {
    font-family: "DM Sans", sans-serif;
    color: #4e4e4e;
}


/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

.ant-tabs-nav {
    width: 100% !important;
}

.ant-tabs-tab {
    display: block;
    flex: 1;
    text-align: center;
    width: 100% !important;
}

.ant-tabs-tab .ant-tabs-tab-btn {
    color: #B6B4B6;
    text-shadow: 0 0 0.25px currentcolor;
    width: 100%;
}

.ant-tabs-nav>div:nth-of-type(1) {
    display: unset !important;
    width: 100% !important;
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
    left: auto;
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
}